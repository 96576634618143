export const FETCHING_MESSAGES = "FETCHING_MESSAGES";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const UPDATING_MESSAGES = "UPDATING_MESSAGES";
export const UPDATE_MESSAGES = "UPDATE_MESSAGES";
export const UPDATE_MESSAGES_SUCCESS = "UPDATE_MESSAGES_SUCCESS";
export const UPDATING_ALERT_MESSAGES = "UPDATING_ALERT_MESSAGES";
export const UPDATE_ALERT_MESSAGES = "UPDATE_ALERT_MESSAGES";
export const UPDATE_ALERT_MESSAGES_SUCCESS = "UPDATE_ALERT_MESSAGES_SUCCESS";
export const REMOVING_ALERT_MESSAGES = "REMOVING_ALERT_MESSAGES";
export const REMOVE_ALERT_MESSAGES_SUCCESS = "REMOVE_ALERT_MESSAGES_SUCCESS";
export const MESSAGES_ERROR = "MESSAGES_ERROR";
