import styled from "styled-components";

export const StyledPatternsContainer = styled.div`
  .flow-box {
    position: fixed;
    right: 0px;
    top: 0px;
    text-align: right;
  }
`;
