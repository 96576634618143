export const FETCHING_PROJECTS = "FETCHING_PROJECTS";
export const FETCHING_PROJECTS_BY_COMPANY_NAME =
  "FETCHING_PROJECTS_BY_COMPANY_NAME";
export const FETCHING_PROJECTS_BY_LOGGED_USER_PROJECTS =
  "FETCHING_PROJECTS_BY_LOGGED_USER_PROJECTS";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const ADDING_PROJECT = "ADDING_PROJECT";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const REMOVING_PROJECT = "REMOVING_PROJECT";
export const REMOVE_PROJECT_SUCCESS = "REMOVE_PROJECT_SUCCESS";
export const UPDATING_PROJECT = "UPDATING_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const PROJECTS_ERROR = "PROJECTS_ERROR";
