export const LOGGING_USER = "LOGGING_USER";
export const REGISTERING_USER = "REGISTERING_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const FETCHING_LOGGED_USER = "FETCHING_LOGGED_USER";
export const FETCH_LOGGED_USER_SUCCESS = "FETCH_LOGGED_USER_SUCCESS";
export const FETCHING_USERS = "FETCHING_USERS";
export const FETCHING_USERS_BY_LOGGED_USER_PROJECTS =
  "FETCHING_USERS_BY_LOGGED_USER_PROJECTS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const LOGGING_OUT_USER = "LOGGING_OUT_USER";
export const LOGGED_OUT_SUCCESS = "LOGGED_OUT_SUCCESS";
export const UPDATING_USER = "UPDATING_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
