import React from "react";
import MessengersContainer from "../store/Messengers/components/MessengersContainer";

const Messengers = () =>{
  return(
    <React.Fragment>
      <MessengersContainer />
    </React.Fragment>    
  )
}

export default Messengers;
