export const FETCHING_COMPANIES = "FETCHING_COMPANIES";
export const FETCHING_COMPANIES_BY_LOGGED_USER_COMPANIES =
  "FETCHING_COMPANIES_BY_LOGGED_USER_COMPANIES";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const ADDING_COMPANY = "ADDING_COMPANY";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const REMOVING_COMPANY = "REMOVING_COMPANY";
export const REMOVE_COMPANY_SUCCESS = "REMOVE_COMPANY_SUCCESS";
export const UPDATING_COMPANY = "UPDATING_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const COMPANIES_ERROR = "COMPANIES_ERROR";
