export const FETCHING_CONTRACTORS = "FETCHING_CONTRACTORS";
export const FETCHING_CONTRACTORS_BY_LOGGED_USER_CONTRACTORS =
  "FETCHING_CONTRACTORS_BY_LOGGED_USER_CONTRACTORS";
export const FETCH_CONTRACTORS_SUCCESS = "FETCH_CONTRACTORS_SUCCESS";
export const ADDING_CONTRACTOR = "ADDING_CONTRACTOR";
export const ADD_CONTRACTOR_SUCCESS = "ADD_CONTRACTOR_SUCCESS";
export const REMOVING_CONTRACTOR = "REMOVING_CONTRACTOR";
export const REMOVE_CONTRACTOR_SUCCESS = "REMOVE_CONTRACTOR_SUCCESS";
export const UPDATING_CONTRACTOR = "UPDATING_CONTRACTOR";
export const UPDATE_CONTRACTOR_SUCCESS = "UPDATE_CONTRACTOR_SUCCESS";
export const CONTRACTORS_ERROR = "CONTRACTORS_ERROR";
